import React, { useState } from "react";
import * as constant from "../Constants.js";

import "react-day-picker/lib/style.css";
import {
  Modal,
  Button,
  TextArea,
  Grid,
  Icon,
  Label,
  Message,
  Divider,
  Popup,
  Accordion,
  Transition
} from "semantic-ui-react";
import Localize from "react-intl-universal";
import {
  formatDateDisplay,
  formatDocumentStatus,
  formatDocumentPaymentStatus,
  translateStatus,
  translateField
} from "../Util.js";
import DocumentFlow from "../DocumentFLow/DocumentFlow";
import WarningOkDialog from "../../Dialogs/WarningOkDialog";
import styles from "./style/DocumentDetails.module.css";
import { union, get } from "lodash";

import AddManualPayment from "./DocumentFormComponents/AddManualPayment";
import * as RestService from "../../RestService";
import ConfirmDeleteDialog from "../../Dialogs/ConfirmDeleteDialog";
import showNotification from "../../Notifications/NotificationFunction";
import * as notificationConstants from "../../Notifications/Constants.js";
import GlobalOkCancelBtn from "../../Components/GlobalOkCancelBtn";
import GlobalStatusActionBtn from "../../Components/GlobalStatusActionBtn";

const DocumentDetails = props => {
  const {
    privileges,
    manualPaymentPrivileges,
    documentNumbers,
    verificationLoading,
    parentDocumentForDiagram,
    parentDocumentForTable,
    setIsLoading,
    setIsLoadingLoader,
    handler
  } = props;
  const [documentZugfredObject] = useState(props.document === undefined ? {} : props.document);
  const [showModal, setShowModal] = useState(false);

  const [actionType, setActionType] = useState("");
  const [reasonNote, setReasonNote] = useState("");
  const [charCounter, setCharCounter] = useState({ charsLeft: 255 });

  const [showMissingPartnerModal, setShowMissingPartnerModal] = useState(false);
  const [showDelModal, setShowDelModal] = useState(false);
  const [showManualPaymentModal, setShowManualPaymentModal] = useState(false);
  const [selectedPayment, setSelectedPayment] = useState("");

  const [editedFields, setEditedFields] = useState([]);
  const [showDiagramFlowState, setShowDiagramFlowState] = useState(false);
  let document = { ...props.document };
  const [documentForDiagram, setDocumentForDiagram] = useState(null);
  let maxChars = 255;

  const [activeAccordion, setActiveAccordion] = useState(constant.initialAccordion);

  const accordionHandler = (e, titleProps) => {
    const { name } = titleProps;
    setActiveAccordion(previousState => {
      return { ...previousState, [name]: !previousState[name] };
    });
  };

  const invoiceOrCorrection = () => {
    return (
      props.document.documentType === constant.documentType.invoice ||
      props.document.documentType === constant.documentType.correction
    );
  };

  const handleOnApproveButtonClick = () => {
    setActionType(constant.actionType.paymentConfirm);
    setShowModal(true);
    document = {
      ...document,
      approvalStatus: constant.approvalStatus.approved,
      documentDue: false
    };
  };

  const handleOnDeclineButtonClick = () => {
    setShowModal(true);
    setActionType(constant.actionType.paymentDecline);
  };
  const handleOnDeclineConfirmButtonClick = () => {
    setDocumentHistory(actionType, reasonNote);
    setShowModal(false);
    setActionType(null);
    document = {
      ...document,
      approvalStatus: constant.paymentApprovalStatus.declined
    };
    props.handler.documentForm.updateDocument(
      document,
      actionType,
      document.history,
      constant.paymentApprovalStatus.approved
    );
    setCharCounter({
      charsLeft: maxChars
    });
  };

  const handleOnApproveConfirmButtonClick = () => {
    setDocumentHistory(actionType, reasonNote);
    setShowModal(false);
    setActionType(null);
    document = {
      ...document,
      approvalStatus: constant.paymentApprovalStatus.approved,
      documentDue: false
    };
    props.handler.documentForm.updateDocument(
      document,
      actionType,
      document.history,
      constant.paymentApprovalStatus.approved
    );
    setCharCounter({
      charsLeft: maxChars
    });
  };

  const handleVerificationOnDeclineConfirmButtonClick = () => {
    setDocumentHistory(actionType, reasonNote);
    setShowModal(false);
    setActionType(null);
    document = {
      ...document,
      verificationStatus: constant.verificationStatus.disapproved
    };
    props.handler.documentForm.updateDocument(
      document,
      actionType,
      document.history,
      constant.verificationStatus.verified
    );
    setCharCounter({
      charsLeft: maxChars
    });
  };

  const handleOnVerificationApproveConfirmButtonClick = () => {
    setDocumentHistory(actionType, reasonNote);
    setShowModal(false);
    setActionType(null);
    document = {
      ...document,
      verificationStatus: constant.verificationStatus.verified
    };
    props.handler.documentForm.updateDocument(
      document,
      actionType,
      document.history,
      constant.verificationStatus.verified
    );
    setCharCounter({
      charsLeft: maxChars
    });
  };

  const handleOnApproveVerificationButtonClick = () => {
    if (
      document &&
      (document.documentType === constant.documentType.invoice ||
        document.documentType === constant.documentType.correction) &&
      !document.partner
    ) {
      setShowMissingPartnerModal(true);
    } else {
      setShowModal(true);
      setActionType(constant.actionType.verificationConfirm);
    }
  };

  const handleOnDeclineVerifcationButtonClick = () => {
    setShowModal(true);
    setActionType(constant.actionType.verificationDecline);
  };

  const handleOnDeclineCancelButtonClick = () => {
    setShowModal(false);
    setActionType(constant.actionType.verificationDecline);
  };

  const handleResetVerificationStatus = () => {
    setActionType(constant.actionType.resetVerificationStatus);
    setShowModal(true);
  };

  const handleResetVerificationStatusConfirm = () => {
    setDocumentHistory(constant.actionType.resetVerificationStatus, reasonNote);
    setActionType(null);
    document = {
      ...document,
      verificationStatus: constant.verificationStatus.unverified,
      approvalStatus: props.document.approvalStatus,
      documentDue: props.document.documentDue
    };
    props.handler.documentForm.updateDocument(
      document,
      constant.actionType.resetVerificationStatus,
      document.history,
      constant.verificationStatus.verified
    );
    setShowModal(false);
    setCharCounter({
      charsLeft: maxChars
    });
  };

  const handleOnResetApprovalStatusButtonClick = () => {
    setActionType(constant.actionType.resetPaymentStatus);
    setShowModal(true);
  };

  const handleOnResetApprovalStatusCofirm = () => {
    setDocumentHistory(constant.actionType.resetPaymentStatus, reasonNote);
    setActionType(null);
    document = {
      ...document,
      approvalStatus: constant.paymentApprovalStatus.unresolved,
      documentDue: true
    };
    props.handler.documentForm.updateDocument(
      document,
      constant.actionType.resetPaymentStatus,
      document.history,
      constant.paymentApprovalStatus.approved
    );
    setShowModal(false);
    setCharCounter({
      charsLeft: maxChars
    });
  };

  const handleReasonChange = (e, data) => {
    setReasonNote(e.target.value);
    let maxChars = 255;
    setCharCounter({
      charsLeft: maxChars - reasonNote.length
    });
  };

  const setDocumentHistory = (actionType, note) => {
    const history = {};

    history.timestamp = new Date();
    history.action = actionType;
    history.note = note;

    if (!document.history) {
      document.history = [];
    }

    document.history.push(history);
  };

  const handleOnCloseMissingPartnerDIalog = () => {
    setShowMissingPartnerModal(false);
  };

  const showNotificationMessage = param => {
    showNotification({
      entity: Localize.get("NOTIFICATIONS.NOTIFICATIONMESSAGES.MANUAL_PAYMENT"),
      ...param
    });
  };

  const onLinkDocumentsButtonClick = (e, data) => {
    e.preventDefault();
    e.stopPropagation();
    if (linkAction) {
      const newArr = [...editedFields];
      if (!editedFields.includes("documents")) {
        newArr.push("documents");
        setEditedFields(newArr);
      }

      if (documentZugfredObject.editedFields && documentZugfredObject.editedFields.length > 0) {
        const editedByref = union(newArr, documentZugfredObject.editedFields);
        documentZugfredObject.editedFields = editedByref;
      } else {
        documentZugfredObject.editedFields = newArr;
      }

      props.handler.documentForm.onLinkDocumentsButtonClick(documentNumbers);
    }
  };

  const getDialog = () => {
    let dialog = null;
    let confirmHandler;
    let cancelHandler;
    let modalHeader;
    let modalColor;
    let textAreaPlaceHolder;

    if (showModal) {
      switch (actionType) {
        case constant.actionType.verificationConfirm:
          modalHeader = Localize.get("DOCUMENTS.MODAL.HEADER_VERIFICATION_APPROVAL");
          confirmHandler = handleOnVerificationApproveConfirmButtonClick;
          cancelHandler = handleOnDeclineCancelButtonClick;
          modalColor = "#228B22";
          textAreaPlaceHolder = Localize.get("DOCUMENTS.MODAL.NOTE_VERIFICATION_APPROVAL");

          break;
        case constant.actionType.verificationDecline:
          modalHeader = Localize.get("DOCUMENTS.MODAL.HEADER_VERIFICATION_DECLINATION");
          confirmHandler = handleVerificationOnDeclineConfirmButtonClick;
          cancelHandler = handleOnDeclineCancelButtonClick;
          modalColor = "#c00";
          textAreaPlaceHolder = Localize.get("DOCUMENTS.MODAL.NOTE_VERIFICATION_DECLINATION");

          break;
        case constant.actionType.paymentConfirm:
          modalHeader = Localize.get("DOCUMENTS.MODAL.HEADER_PAYMENT_APPROVAL");
          confirmHandler = handleOnApproveConfirmButtonClick;
          cancelHandler = handleOnDeclineCancelButtonClick;
          modalColor = "#228B22";
          textAreaPlaceHolder = Localize.get("DOCUMENTS.MODAL.NOTE_PAYMENT_APPROVAL");
          break;
        case constant.actionType.paymentDecline:
          modalHeader = Localize.get("DOCUMENTS.MODAL.HEADER_PAYMENT_DECLINATION");
          confirmHandler = handleOnDeclineConfirmButtonClick;
          cancelHandler = handleOnDeclineCancelButtonClick;
          modalColor = "#c00";
          textAreaPlaceHolder = Localize.get("DOCUMENTS.MODAL.NOTE_PAYMENT_DECLINATION");
          break;
        case constant.actionType.resetVerificationStatus:
          modalHeader = Localize.get("DOCUMENTS.MODAL.HEADER_VERIFICATION_RESET");
          confirmHandler = handleResetVerificationStatusConfirm;
          cancelHandler = handleOnDeclineCancelButtonClick;
          modalColor = "#228B22";
          textAreaPlaceHolder = Localize.get("DOCUMENTS.MODAL.NOTE_VERIFICATION_RESET");
          break;
        case constant.actionType.resetPaymentStatus:
          modalHeader = Localize.get("DOCUMENTS.MODAL.HEADER_APPROVAL_RESET");
          confirmHandler = handleOnResetApprovalStatusCofirm;
          cancelHandler = handleOnDeclineCancelButtonClick;
          modalColor = "#228B22";
          textAreaPlaceHolder = Localize.get("DOCUMENTS.MODAL.NOTE_APPROVAL_RESET");
          break;

        default:
      }
    }

    if (showModal) {
      dialog = (
        <Modal
          size="tiny"
          dimmer="inverted"
          open={true}
          closeOnEscape={false}
          closeOnDimmerClick={false}
        >
          <Modal.Header
            className={styles.dialogHeader}
            style={{
              backgroundColor: modalColor
            }}
          >
            {modalHeader}
          </Modal.Header>
          <Modal.Content>
            <p>
              {Localize.get("DOCUMENTS.MODAL.CHAR_COUNTER")} {charCounter.charsLeft}
            </p>
            <TextArea
              placeholder={textAreaPlaceHolder}
              rows={6}
              className={styles.modal_text_area}
              onChange={handleReasonChange}
              maxLength={255}
            />
          </Modal.Content>
          <Modal.Actions>
            {/* <Button
              onClick={confirmHandler}
              positive
              labelPosition="right"
              icon="checkmark"
              content={Localize.get("DOCUMENTS.MODAL.MODAL_CONFIRM")}
            /> */}

            <GlobalOkCancelBtn
              onConfirmClicked={confirmHandler}
              onCancel={cancelHandler}
              btnText={Localize.get("DOCUMENTS.MODAL.MODAL_CONFIRM")}
            />
          </Modal.Actions>
        </Modal>
      );
    }
    return dialog;
  };
  const getManualPaymentDialog = () => {
    let dialog = null;
    if (showManualPaymentModal) {
      dialog = (
        <Modal
          size="mini"
          open={true}
          dimmer="blurring"
          closeOnEscape={true}
          closeOnDimmerClick={false}
          onClose={e => setShowManualPaymentModal(false)}
          centered={true}
          closeIcon={true}
        >
          <Modal.Header>
            {Localize.get("DOCUMENTS.PAYMENT.MANUAL.ADD_PAYMENT") + document.documentNumber}
          </Modal.Header>
          <AddManualPayment
            setShowManualPaymant={() => setShowManualPaymentModal(false)}
            manualPaymentPrivileges={manualPaymentPrivileges}
            document={document}
            paymentForm={props.handler.paymentForm}
            documentForm={props.handler.documentForm}
          />
        </Modal>
      );
    }
    return dialog;
  };

  const getMissingPartnerDialog = () => {
    let dialog = null;
    let modalHeaderForDocuments = Localize.get("DOCUMENTS.MODAL.HEADER_VERIFICATION_REJECT");

    let modalContentForDocuments;

    if (!document.partner) {
      modalContentForDocuments = Localize.get("DOCUMENTS.MODAL.MODAL_MISSING_PARTNER");
    } else if (
      get(document, "document.partner.accounts") &&
      get(document, "document.partner.accounts").length === 0
    ) {
      modalContentForDocuments = Localize.get("DOCUMENTS.MODAL.MODAL_MISSING_PARTNER_BANK");
    }

    if (showMissingPartnerModal) {
      dialog = (
        <WarningOkDialog
          open={showMissingPartnerModal}
          modalHeader={modalHeaderForDocuments}
          modalContent={modalContentForDocuments}
          onOkFunction={handleOnCloseMissingPartnerDIalog}
        />
      );
    }
    return dialog;
  };
  const getRevenuePaymentStatus = () => {
    let statusLabel;
    switch (props.document.paymentStatus) {
      case constant.revenuePaymentStatus.paid:
        statusLabel = (
          <Label size="medium" className={styles.labelStatusVerified}>
            {Localize.get("DOCUMENTS.ITEMLIST.ITEM_LABEL.PAID")}
          </Label>
        );
        break;
      case constant.revenuePaymentStatus.partialPaid:
        statusLabel = (
          <Label size="medium" className={styles.labelStatusUnresolved}>
            {Localize.get("DOCUMENTS.ITEMLIST.ITEM_LABEL.PARTIAL_PAID")}
          </Label>
        );

        break;
      case constant.revenuePaymentStatus.notPaid:
        statusLabel = (
          <Label size="medium" className={styles.labelStatusDunning}>
            {Localize.get("DOCUMENTS.ITEMLIST.ITEM_LABEL.NOT_PAID")}
          </Label>
        );

        break;
      default:
        break;
    }

    return statusLabel;
  };

  const getStatus = () => {
    let dueBlock;
    let verificationAction;
    let paymentApprovalBlock;
    let manualPaymentBlock;
    let approvalAction;
    let progressStatusBlock;
    let verificationRulesDisabler =
      document && document.actions && document.actions.VerificationRules
        ? !privileges.verify || !document.actions.VerificationRules.verify
        : null;
    let verifyResetDisabler =
      document && document.actions && document.actions.VerificationRules
        ? !privileges.verify || !document.actions.VerificationRules.resetVerify
        : null;
    let approvalRulesDisabler =
      document && document.actions && document.actions.ApprovalRules
        ? !privileges.approve || !document.actions.ApprovalRules.approve
        : null;
    let resetApproval =
      document && document.actions && document.actions.ApprovalRules
        ? !privileges.approve || !document.actions.ApprovalRules.resetApproval
        : null;
    let isLinkedWithCorrection =
      document &&
      document.documents &&
      document.documents.map(doc => doc.documentType === "correction").length > 0;

    if (
      document.actions &&
      document.actions.VerificationRules != null &&
      (document.actions.VerificationRules.verify != null ||
        document.actions.VerificationRules.resetVerify != null)
    ) {
      verificationAction = (
        <GlobalStatusActionBtn
          approve={document.actions.VerificationRules.verify != null}
          reset={document.actions.VerificationRules.resetVerify != null}
          disabledApprove={!verificationRulesDisabler}
          disabledDecline={!privileges.verify || !document.actions.VerificationRules.decline}
          disabledReset={!verifyResetDisabler}
          popupApproveHeaderText={Localize.get("DOCUMENTS.DETAILS.VERIFY_REQUIREMENT_HEADER")}
          popupApproveContentText={
            props.document !== undefined &&
            props.document !== null &&
            props.document.direction === constant.documentDirection.outgoing
              ? Localize.get("DOCUMENTS.DETAILS.VERIFY_REVENUE_REQUIREMENT_CONTENT")
              : Localize.get("DOCUMENTS.DETAILS.VERIFY_REQUIREMENT_CONTENT")
          }
          popupResetContentText={
            props.document !== undefined &&
            props.document !== null &&
            props.document.direction === constant.documentDirection.outgoing
              ? isLinkedWithCorrection
                ? Localize.get(
                    "DOCUMENTS.DETAILS.VERIFICATION_APPROVE_WITH_CORRECTION_RESET_CONTENT"
                  )
                : Localize.get("DOCUMENTS.DETAILS.APPROVE_RESET_CONTENT")
              : isLinkedWithCorrection
              ? Localize.get("DOCUMENTS.DETAILS.VERIFICATION_APPROVE_WITH_CORRECTION_RESET_CONTENT")
              : Localize.get("DOCUMENTS.DETAILS.VERIFICATION_APPROVE_RESET_CONTENT")
          }
          statusLoading={verificationLoading}
          onApproveClick={handleOnApproveVerificationButtonClick}
          onDeclineClick={handleOnDeclineVerifcationButtonClick}
          onResetClick={handleResetVerificationStatus}
        />
      );
    }

    if (
      document.actions &&
      document.actions.ApprovalRules &&
      (document.actions.ApprovalRules.approve != null ||
        document.actions.ApprovalRules.resetApproval != null)
    ) {
      approvalAction = (
        <GlobalStatusActionBtn
          approve={document.actions.ApprovalRules.approve != null}
          reset={document.actions.ApprovalRules.resetApproval != null}
          disabledApprove={!approvalRulesDisabler}
          disabledDecline={!privileges.approve || !document.actions.ApprovalRules.disapprove}
          disabledReset={!resetApproval}
          popupApproveContentText={Localize.get("DOCUMENTS.DETAILS.APPROVE_CONTENT")}
          popupResetContentText={Localize.get("DOCUMENTS.DETAILS.APPROVE_RESET_CONTENT")}
          statusLoading={verificationLoading}
          onApproveClick={handleOnApproveButtonClick}
          onDeclineClick={handleOnDeclineButtonClick}
          onResetClick={handleOnResetApprovalStatusButtonClick}
        />
      );
      paymentApprovalBlock = (
        <>
          <Grid.Column computer={5} verticalAlign="middle">
            <div className={styles.labelText}>
              {Localize.get("DOCUMENTS.DETAILS.PAYMENT_STATUS")}
            </div>
          </Grid.Column>
          <Grid.Column computer={4} verticalAlign="middle" className={styles.status_padding_reset}>
            {document.approvalStatus === constant.paymentApprovalStatus.approved && (
              <Label size="medium" className={styles.labelStatusApproved}>
                {Localize.get("DOCUMENTS.ITEMLIST.ITEM_LABEL.PAYMENT_APPROVED")}
              </Label>
            )}
            {document.approvalStatus === constant.paymentApprovalStatus.declined && (
              <Label size="medium" className={styles.labelStatusDeclined}>
                {Localize.get("DOCUMENTS.ITEMLIST.ITEM_LABEL.PAYMENT_DECLINED")}
              </Label>
            )}
            {document.approvalStatus === constant.paymentApprovalStatus.unresolved && (
              <Label size="medium" className={styles.labelStatusUnresolved}>
                {Localize.get("DOCUMENTS.ITEMLIST.ITEM_LABEL.PAYMENT_UNRESOLVED")}
              </Label>
            )}
          </Grid.Column>
          <Grid.Column computer={7} verticalAlign="middle">
            {approvalAction}
          </Grid.Column>
        </>
      );
    }

    if (
      document.actions &&
      document.actions.PaymentRules &&
      document.actions.PaymentRules.allowManualPayment !== undefined &&
      document.actions.PaymentRules.allowManualPayment !== null
    ) {
      manualPaymentBlock = (
        <>
          <Grid.Column computer={5} verticalAlign="middle">
            <div className={styles.labelText}>
              {Localize.get("DOCUMENTS.DETAILS.MANUAL_PAYMENT")}
            </div>
          </Grid.Column>
          <Grid.Column computer={3} verticalAlign="middle" className={styles.status_padding_reset}>
            <Button
              disabled={
                !manualPaymentPrivileges.create || !document.actions.PaymentRules.allowManualPayment
              }
              className={`${styles.allButtons} ${styles.manualPay}`}
              positive
              size="mini"
              basic
              onClick={() => setShowManualPaymentModal(true)}
            >
              {Localize.get("DOCUMENTS.DETAILS.BUTTON_MANUAL_PAYMENT")}
            </Button>
          </Grid.Column>
        </>
      );
    }

    if (documentZugfredObject.direction === constant.documentDirection.outgoing) {
      manualPaymentBlock = (
        <>
          <Grid.Column computer={5} verticalAlign="middle">
            <div className={styles.labelText}>
              {Localize.get("DOCUMENTS.DETAILS.REVENUE_PAYMENT_STATUS")}
            </div>
          </Grid.Column>
          <Grid.Column computer={4} verticalAlign="middle">
            {" "}
            {getRevenuePaymentStatus()}
          </Grid.Column>
          <Grid.Column computer={4} verticalAlign="middle" className={styles.status_padding_reset}>
            <Button
              disabled={
                !manualPaymentPrivileges.create ||
                !get(document, "actions.PaymentRules.allowManualPayment")
              }
              className={`${styles.allButtons} ${styles.manualPay}`}
              positive
              size="mini"
              basic
              onClick={() => setShowManualPaymentModal(true)}
            >
              {Localize.get("DOCUMENTS.DETAILS.BUTTON_MANUAL_PAYMENT")}
            </Button>
          </Grid.Column>
        </>
      );
    }

    if (document && document.progressStatus) {
      let progressStatus;
      let classProgressStatus;
      switch (document.progressStatus) {
        case constant.progressStatus.imported:
          classProgressStatus = styles.labelStatusImpotred;
          progressStatus = Localize.get("DOCUMENTS.ITEMLIST.ITEM_LABEL.PROGRESS_IMPORTED");
          break;
        case constant.progressStatus.pdfCreated:
          classProgressStatus = `ui label ${styles.labelStatusPDFCreated}`;
          progressStatus = Localize.get("DOCUMENTS.ITEMLIST.ITEM_LABEL.PROGRESS_PDF_CREATED");
          break;
        case constant.progressStatus.sent:
          classProgressStatus = `ui label ${styles.labelStatusSentnt}`;
          progressStatus = Localize.get("DOCUMENTS.ITEMLIST.ITEM_LABEL.PROGRESS_SENT");
          break;
        case constant.progressStatus.paid:
          classProgressStatus = `ui label ${styles.labelStatusPaid}`;
          progressStatus = Localize.get("DOCUMENTS.ITEMLIST.ITEM_LABEL.PROGRESS_PAID");
          break;
        case constant.progressStatus.dunning:
          classProgressStatus = `ui label ${styles.labelStatusDunning}`;
          progressStatus = Localize.get("DOCUMENTS.ITEMLIST.ITEM_LABEL.PROGRESS_DUNNING");
          break;
        default:
          break;
      }

      progressStatusBlock = (
        <>
          <Grid.Column
            mobile={16}
            tablet={2}
            computer={5}
            verticalAlign="middle"
            className={styles.status_col_label}
          >
            <div className={styles.labelText}>
              {Localize.get("DOCUMENTS.SIDEBAR.HEADER_STATUS")}
            </div>
          </Grid.Column>
          <Grid.Column
            mobile={16}
            tablet={4}
            computer={4}
            verticalAlign="middle"
            className={styles.status_padding_reset}
          >
            <Label size="medium" className={classProgressStatus}>
              {progressStatus.toUpperCase()}
            </Label>
          </Grid.Column>
        </>
      );
    }

    return (
      <>
        {dueBlock}

        <Grid columns={3} className={styles.payment_status_grid}>
          <Grid.Row className={styles.padding_top_zero}>
            <Grid.Column
              mobile={16}
              tablet={2}
              computer={5}
              verticalAlign="middle"
              className={styles.status_col_label}
            >
              <div className={styles.labelText}>
                {Localize.get("DOCUMENTS.DETAILS.VERIFICATION_STATUS")}
              </div>
            </Grid.Column>
            <Grid.Column
              mobile={16}
              tablet={4}
              computer={4}
              verticalAlign="middle"
              className={styles.status_padding_reset}
            >
              {document.verificationStatus === constant.verificationStatus.verified && (
                <Label size="medium" className={styles.labelStatusVerified}>
                  {Localize.get("DOCUMENTS.ITEMLIST.ITEM_LABEL.VERIFICATION_VERIFIED")}
                </Label>
              )}
              {document.verificationStatus === constant.verificationStatus.disapproved && (
                <Label size="medium" className={styles.labelStatusDeclined}>
                  {Localize.get("DOCUMENTS.ITEMLIST.ITEM_LABEL.VERIFICATION_DISAPPROVED")}
                </Label>
              )}
              {document.verificationStatus === constant.verificationStatus.unverified && (
                <Label size="medium" className={styles.labelStatusUnresolved}>
                  {Localize.get("DOCUMENTS.ITEMLIST.ITEM_LABEL.VERIFICATION_UNVERIFIED")}
                </Label>
              )}
            </Grid.Column>
            <Grid.Column mobile={16} tablet={8} computer={7} verticalAlign="middle">
              {verificationAction}
            </Grid.Column>
          </Grid.Row>
          {paymentApprovalBlock && (
            <Grid.Row className={styles.padding_top_zero}>{paymentApprovalBlock}</Grid.Row>
          )}
          {progressStatusBlock && (
            <Grid.Row className={styles.padding_top_zero}>{progressStatusBlock}</Grid.Row>
          )}
          {manualPaymentBlock && (
            <Grid.Row className={styles.padding_top_zero}>{manualPaymentBlock}</Grid.Row>
          )}
        </Grid>
      </>
    );
  };

  const checkOrderItems = data => {
    return !!data.id;
  };

  const showProjectHandler = e => {
    e.preventDefault();
    e.stopPropagation();
    if (linkAction) {
      handler.showProjectsHandler();
    }
  };

  const showScanHandler = () => {
    handler.showScanHandler();
  };

  const dialogHandler = {
    confirmDeleteDialog: {
      handleOnDeclineCancelButtonClick: () => {
        setShowDelModal(false);
      },
      handleDeleteConfirmButtonClick: () => {
        const serviceDelete = new RestService.Payment.Delete.Service(selectedPayment.id);
        setIsLoading(true);
        serviceDelete.query.addDocumentId(document.id);
        serviceDelete.setCallbackSuccess(callbackDeleteSuccess);
        serviceDelete.setCallbackError(callbackDeleteError);
        serviceDelete.setCallbackFinally(callbackDeleteFinally);
        serviceDelete.setPayload();
        serviceDelete.call();
        setShowDelModal(false);
      }
    }
  };

  const handleOnDeleteClick = (e, payment) => {
    e.preventDefault();
    setShowDelModal(true);
    setSelectedPayment(payment);
  };

  const callbackDeleteSuccess = () => {
    props.handler.documentForm.updateDocumentForManualPayment(props.document.id);
    showNotificationMessage({
      type: notificationConstants.notificationType.info,
      operation: notificationConstants.notificationOperation.delete
    });

    setDocumentHistory(
      constant.actionType.deleteManualPayment,
      Localize.get("GLOBAL.DETAILS.AMOUNT") +
        ": " +
        new Intl.NumberFormat("de-DE", {
          style: "currency",
          currency: "EUR"
        }).format(selectedPayment.amount)
    );
  };
  const callbackDeleteError = () => {
    showNotificationMessage({
      type: notificationConstants.notificationType.warning,
      operation: notificationConstants.notificationOperation.delete
    });
  };
  const callbackDeleteFinally = () => {
    setIsLoading(false);
  };

  let linkAction =
    document.actions && document.actions.LinkRules ? document.actions.LinkRules.link : false;

  const getLinkedDocumentsTableAndDiagram = () => {
    let documentForDiagram = null;
    let documentForTable = null;

    if (document.parentDocument === null || !document.parentDocument) {
      // parent document
      documentForDiagram = props.document;
      documentForTable = document.documents ? document.documents : [];
    } else {
      if (document.documents && document.documents.length === 0) {
        // child document
        documentForDiagram = parentDocumentForDiagram;
        documentForTable = [parentDocumentForTable];
      } else {
        // parent and child document
        documentForDiagram = parentDocumentForDiagram;
        documentForTable = union([parentDocumentForTable], document.documents);
      }
    }

    return (
      <>
        <Accordion className={styles.details_accordion}>
          <Accordion.Title
            index={3}
            name="linked"
            active={activeAccordion.linked}
            onClick={accordionHandler}
            className={styles.dms_accordiion_title}
          >
            {activeAccordion.linked ? <Icon name="caret down" /> : <Icon name="caret left" />}
            {documentForDiagram && documentForTable && documentForTable.length ? (
              <Icon
                className={styles.edit_link_docs_icon}
                name="sitemap"
                onClick={() => setShowDiagramFlowState(true)}
              />
            ) : null}
            <Icon
              onClick={onLinkDocumentsButtonClick}
              name="edit"
              className={
                linkAction
                  ? styles.edit_link_docs_icon
                  : `${styles.edit_link_docs_icon} ${styles.custom_disabled}`
              }
            />
            {Localize.get("DOCUMENTS.DETAILS.LINKED_DOCUMENT")}
          </Accordion.Title>
          <Transition visible={activeAccordion.linked} animation="fade down" duration={300}>
            <Accordion.Content active={activeAccordion.linked}>
              {documentForDiagram && documentForTable && documentForTable.length ? (
                <table
                  className={`ui celled table documentLinkedDocumentTable ${styles.bms_item_table}`}
                >
                  <thead>
                    <tr>
                      <th>{Localize.get("PROJECTS.MANAGEMENT.LINK_DOCUMENTS_NUMBER")}</th>
                      <th>{Localize.get("PROJECTS.MANAGEMENT.LINK_DOCUMENTS_TYPE")}</th>
                      <th>{Localize.get("PROJECTS.MANAGEMENT.LINK_DOCUMENTS_STATUS")}</th>
                      <th>{Localize.get("PROJECTS.MANAGEMENT.LINK_DOCUMENTS_DATE")}</th>
                      <th>{Localize.get("PROJECTS.MANAGEMENT.LINK_DOCUMENTS_PREVIEW")}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {documentForTable.map(doc => {
                      const {
                        id,
                        isParent,
                        documentNumber,
                        documentType,
                        verificationStatus,
                        documentDate
                      } = doc;

                      return (
                        <tr key={id}>
                          <td>
                            {isParent ? (
                              <Popup
                                size="mini"
                                content={Localize.get("DOCUMENTS.THUMBNAILS.PARENT_DOCUMENT")}
                                trigger={<Icon name="anchor" size="mini" circular={true} />}
                              />
                            ) : (
                              <div />
                            )}
                            {documentNumber}
                          </td>
                          <td>
                            {Localize.get(
                              `DOCUMENTS.THUMBNAILS.DOCUMENT_TYPE_${documentType
                                .toString()
                                .toUpperCase()}`
                            )}
                          </td>
                          <td>{formatDocumentStatus(verificationStatus)}</td>
                          <td>{formatDateDisplay(documentDate)}</td>
                          <td className={styles.center_cell}>
                            {" "}
                            {props.state.activeRightPanelMode ===
                            constant.rightPanelMode.documentDetails ? (
                              <Icon
                                onClick={() => props.handler.showLinkedDocumentHandler(doc)}
                                name="eye"
                                size="large"
                                disabled={documentType === constant.documentType.imported_invoice}
                              />
                            ) : (
                              <Icon
                                onClick={props.handler.hideLinkedDocument}
                                name="eye slash"
                                size="large"
                              />
                            )}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              ) : (
                <Message icon className={styles.no_data_msg}>
                  <Icon name="info circle" />
                  {Localize.get("DOCUMENTS.ITEMLIST.TOOLBAR.EMPTY_DATA")}
                </Message>
              )}
            </Accordion.Content>
          </Transition>
        </Accordion>
      </>
    );
  };

  let documentDetails = null;

  let incomingOutgoing;
  // eslint-disable-next-line no-unused-vars
  let headerPayments;
  let subtotals;

  const getTaxName = tax => {
    switch (tax) {
      case 0:
        return Localize.get("DOCUMENTS.ORDERITEMS.TAX0");
      case 5:
        return Localize.get("DOCUMENTS.ORDERITEMS.TAX5");
      case 7:
        return Localize.get("DOCUMENTS.ORDERITEMS.TAX7");
      case 16:
        return Localize.get("DOCUMENTS.ORDERITEMS.TAX16");
      case 19:
        return Localize.get("DOCUMENTS.ORDERITEMS.TAX19");
      default:
        break;
    }
  };

  if (
    props.document !== undefined &&
    props.document !== null &&
    props.document.direction === constant.documentDirection.outgoing
  ) {
    incomingOutgoing = Localize.get("TRANSACTIONS.DIRECTION.REVENUE");
    headerPayments = Localize.get("DOCUMENTS.DETAILS.HEADER_PAYMENTS_INCOMING");
  } else {
    incomingOutgoing = Localize.get("TRANSACTIONS.DIRECTION.EXPENSE");
    headerPayments = Localize.get("DOCUMENTS.DETAILS.HEADER_PAYMENTS_OUTGOING");
  }

  let compareSubTotals = (a, b) => {
    if (a.tax < b.tax) {
      return -1;
    }
    if (a.tax > b.tax) {
      return 1;
    }
    return 0;
  };

  if (props.document.taxSubtotals) {
    let sortSubtotals = props.document.taxSubtotals.sort(compareSubTotals);
    sortSubtotals = sortSubtotals.filter(element => element.calculatedTax >= 0);
    subtotals = sortSubtotals.map((sub, index) => (
      <div key={index} className="item">
        <div className={`right floated content ${styles.details_partner}`}>{sub.calculatedTax}</div>
        <div className="content">{getTaxName(sub.tax)}</div>
      </div>
    ));
  }

  if (props.document) {
    documentDetails = (
      <div>
        <Grid columns="1" stackable container className={styles.margin_top_zero}>
          <Grid.Column className={styles.main_column_padding}>
            <div>{getStatus()}</div>

            <div className={styles.bms_details_no_accordion}>
              {incomingOutgoing}
              <div className={styles.currency}>
                {document.documentType !== "delivery" ? (
                  new Intl.NumberFormat("de-DE", {
                    style: "currency",
                    currency: "EUR"
                  }).format(props.document.amount)
                ) : (
                  <div />
                )}
              </div>
            </div>

            <Accordion className={styles.details_accordion}>
              <Accordion.Title
                index={0}
                name="details"
                active={activeAccordion.details}
                onClick={accordionHandler}
                className={styles.dms_accordiion_title}
              >
                {activeAccordion.details ? <Icon name="caret down" /> : <Icon name="caret left" />}
                {props.state.activeRightPanelMode === constant.rightPanelMode.documentDetails ? (
                  <Popup
                    content={Localize.get("DOCUMENTS.DETAILS.DOC_PREVIEW")}
                    trigger={
                      <Icon
                        name="eye"
                        size="large"
                        onClick={showScanHandler}
                        disabled={
                          props.document.documentType === constant.documentType.imported_invoice
                        }
                      />
                    }
                  />
                ) : (
                  <Icon name="eye slash" size="large" onClick={showScanHandler} />
                )}
                {Localize.get("DOCUMENTS.DETAILS.DOCUMENTS_DETAILS")}
              </Accordion.Title>

              <Transition visible={activeAccordion.details} animation="fade down" duration={300}>
                <Accordion.Content active={activeAccordion.details}>
                  <div className={`ui middle aligned divided list ${styles.bms_list_accordion}`}>
                    <div className="item">
                      <div className="right floated content">
                        {props.document.documentType
                          ? Localize.get(
                              `DOCUMENTS.THUMBNAILS.DOCUMENT_TYPE_${props.document.documentType
                                .toString()
                                .toUpperCase()}`
                            )
                          : ""}
                      </div>
                      <div className="content">
                        {Localize.get("DOCUMENTS.DETAILS.DOCUMENT_TYPE")}
                      </div>
                    </div>
                    <div className="item">
                      <div className={`right content ${styles.wrap_column}`}>
                        {props.document.documentNumber}
                      </div>
                      <div className="content">
                        {Localize.get("DOCUMENTS.DETAILS.OUTPUT_DOCUMENT_NUMBER")}
                      </div>
                    </div>
                    <div className="item">
                      <div className="right floated content">
                        {formatDateDisplay(props.document.createdAt)}
                      </div>
                      <div className="content">{Localize.get("DOCUMENTS.DETAILS.CREATED_ON")}</div>
                    </div>
                    <div className="item">
                      <div className="right floated content">
                        {props.document.documentDate
                          ? formatDateDisplay(props.document.documentDate)
                          : ""}
                      </div>
                      <div className="content">
                        {Localize.get("DOCUMENTS.DETAILS.OUTPUT_DOCUMENT_DATE")}
                      </div>
                    </div>
                    {props.document.documentType === constant.documentType.invoice ? (
                      <div className="item">
                        <div className="right floated content">
                          {props.document.paymentDue
                            ? formatDateDisplay(props.document.paymentDue)
                            : ""}
                        </div>
                        <div className="content">
                          {Localize.get("DOCUMENTS.DETAILS.PAYMENT_DUE")}
                        </div>
                      </div>
                    ) : (
                      <div></div>
                    )}
                    {props.document.documentType === constant.documentType.invoice ||
                    props.document.documentType === constant.documentType.correction ||
                    props.document.documentType === constant.documentType.delivery ? (
                      <div className="item">
                        <div className="right floated content">
                          {props.document.deliveryDate
                            ? formatDateDisplay(props.document.deliveryDate)
                            : ""}
                        </div>
                        <div className="content">
                          {Localize.get("DOCUMENTS.DETAILS.DELIVERY_DATE")}
                        </div>
                      </div>
                    ) : (
                      <div></div>
                    )}
                    {props.document.partner ? (
                      <div className="item">
                        <div className={`right floated content ${styles.details_partner}`}>
                          {props.document.partner ? props.document.partner.name : ""}
                        </div>
                        <div className="content">{Localize.get("DOCUMENTS.FORM.PARTNER")}</div>
                      </div>
                    ) : (
                      <div></div>
                    )}
                    {subtotals}
                  </div>
                </Accordion.Content>
              </Transition>
            </Accordion>

            {document.description && (
              <Accordion className={styles.details_accordion}>
                <Accordion.Title
                  index={6}
                  name="notes"
                  active={activeAccordion.notes}
                  onClick={accordionHandler}
                  className={styles.dms_accordiion_title}
                >
                  {activeAccordion.notes ? <Icon name="caret down" /> : <Icon name="caret left" />}

                  {Localize.get("DOCUMENTS.DETAILS.HEADER_DESCRIPTION")}
                </Accordion.Title>
                <Transition visible={activeAccordion.notes} animation="fade down" duration={300}>
                  <Accordion.Content active={activeAccordion.notes}>
                    <div className="item">
                      <div className="content">
                        <div className={styles.wrap_column_description}>
                          {props.document.description}
                        </div>
                      </div>
                      <Divider />
                    </div>
                  </Accordion.Content>
                </Transition>
              </Accordion>
            )}

            <Accordion className={styles.details_accordion}>
              <Accordion.Title
                index={1}
                name="items"
                active={activeAccordion.items}
                onClick={accordionHandler}
                className={styles.dms_accordiion_title}
              >
                {activeAccordion.items ? <Icon name="caret down" /> : <Icon name="caret left" />}
                {Localize.get("DOCUMENTS.FORM.DOCUMENT_ITEMS")}
              </Accordion.Title>

              <Transition visible={activeAccordion.items} animation="fade down" duration={200}>
                <Accordion.Content active={activeAccordion.items}>
                  <div className={styles.table_width}>
                    {props.document.orderItems &&
                    props.document.orderItems.some(checkOrderItems) ? (
                      <table className={`ui celled table ${styles.bms_item_table}`}>
                        <thead>
                          <tr>
                            <th>{Localize.get("DOCUMENTS.NEWDOCUMENT.DOCUMENT_LINE")}</th>
                            <th>{Localize.get("DOCUMENTS.NEWDOCUMENT.SELLER_ITEM_ID")}</th>
                            {invoiceOrCorrection() && (
                              <th>{Localize.get("DOCUMENTS.NEWDOCUMENT.ORDER_ID")}</th>
                            )}
                            <th>{Localize.get("DOCUMENTS.NEWDOCUMENT.NAME")}</th>
                            {document.documentType !== "delivery" && (
                              <>
                                <th>{Localize.get("DOCUMENTS.NEWDOCUMENT.NET_PRICE")}</th>
                              </>
                            )}
                            <th>{Localize.get("DOCUMENTS.NEWDOCUMENT.QUANTITY")}</th>
                            <th>{Localize.get("DOCUMENTS.NEWDOCUMENT.DOCUMENT_UNIT")}</th>
                            {document.documentType !== "delivery" && (
                              <>
                                <th>{Localize.get("GLOBAL.DETAILS.AMOUNT")}</th>
                                <th>{Localize.get("DOCUMENTS.FORM.TAX")}</th>
                              </>
                            )}
                            <th>{Localize.get("DOCUMENTS.NEWDOCUMENT.NOTE")}</th>
                          </tr>
                        </thead>
                        <tbody>
                          {props.document.orderItems ? (
                            props.document.orderItems.map(item => (
                              <tr key={item.id}>
                                <td>{item.itemLineId}</td>
                                {invoiceOrCorrection() && <td>{item.sellerItemId}</td>}
                                <td>{item.orderId}</td>
                                <td>{item.name}</td>
                                {document.documentType !== "delivery" && (
                                  <>
                                    <td>{item.listPrice}</td>
                                  </>
                                )}
                                <td>{item.quantity}</td>
                                <td>{item.unit ? item.unit.unit : ""} </td>
                                {document.documentType !== "delivery" && (
                                  <>
                                    <td>{item.amount}</td>
                                    <td>{item.vat}</td>
                                  </>
                                )}
                                <td className={styles.table_note}>
                                  {item.comment ? (
                                    <Popup
                                      content={item.comment}
                                      trigger={<Icon name="sticky note outline" />}
                                    />
                                  ) : null}
                                </td>
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td>{Localize.get("DOCUMENTS.NEWDOCUMENT.NO_DATA")}</td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    ) : (
                      <Message icon className={styles.no_data_msg}>
                        <Icon name="info circle" />
                        {Localize.get("DOCUMENTS.ITEMLIST.TOOLBAR.EMPTY_DATA")}
                      </Message>
                    )}
                  </div>
                </Accordion.Content>
              </Transition>
            </Accordion>

            <Accordion className={styles.details_accordion}>
              <Accordion.Title
                index={2}
                name="projects"
                active={activeAccordion.projects}
                onClick={accordionHandler}
                className={styles.dms_accordiion_title}
              >
                {activeAccordion.projects ? <Icon name="caret down" /> : <Icon name="caret left" />}
                <Icon
                  onClick={showProjectHandler}
                  name="edit"
                  className={
                    linkAction
                      ? styles.edit_link_docs_icon
                      : `${styles.edit_link_docs_icon} ${styles.custom_disabled}`
                  }
                />
                {Localize.get("DOCUMENTS.NEWDOCUMENT.PROJECT_ASSIGNED")}
              </Accordion.Title>
              <Transition visible={activeAccordion.projects} animation="fade down" duration={300}>
                <Accordion.Content active={activeAccordion.projects}>
                  {props.document.projects && props.document.projects.length > 0 ? (
                    <table className={`ui celled table ${styles.bms_item_table}`}>
                      <thead>
                        <tr>
                          <th> {Localize.get("DOCUMENTS.NEWDOCUMENT.PROJECT_NAME")}</th>
                          <th> {Localize.get("DOCUMENTS.FORM.PROJECT_NUMBER")}</th>
                          <th> {Localize.get("GLOBAL.DETAILS.AMOUNT")}</th>
                          <th> {Localize.get("GLOBAL.DETAILS.DESCRIPTION")}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {props.document.projects.map(project => (
                          <tr key={project[0].project.id}>
                            <td>{project[0].project.projectName}</td>
                            <td>{project[0].project.id}</td>
                            <td>{project[0].amount}</td>
                            <td>{project[0].description}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  ) : (
                    <Message icon className={styles.no_data_msg}>
                      <Icon name="info circle" />
                      {Localize.get("DOCUMENTS.ITEMLIST.TOOLBAR.EMPTY_DATA")}
                    </Message>
                  )}
                </Accordion.Content>
              </Transition>
            </Accordion>

            <div>{getLinkedDocumentsTableAndDiagram()}</div>

            <Accordion className={styles.details_accordion}>
              <Accordion.Title
                index={4}
                name="comments"
                active={activeAccordion.comments}
                onClick={accordionHandler}
                className={styles.dms_accordiion_title}
              >
                {" "}
                {activeAccordion.comments ? <Icon name="caret down" /> : <Icon name="caret left" />}
                {Localize.get("DOCUMENTS.DETAILS.DOCUMENT_COMMENTS")}
              </Accordion.Title>
              <Transition visible={activeAccordion.comments} animation="fade down" duration={300}>
                <Accordion.Content active={activeAccordion.comments}>
                  {props.documentsCommentHistory && props.documentsCommentHistory.length > 0 ? (
                    <table className={`ui celled table ${styles.bms_item_table}`}>
                      <thead>
                        <tr>
                          <th>{Localize.get("DOCUMENTS.DETAILS.DOCUMENT_DATE")}</th>
                          <th>{Localize.get("DOCUMENTS.ITEMLIST.HEADER.STATUS")}</th>
                          <th>{Localize.get("DOCUMENTS.DETAILS.OLD_VALUE")}</th>
                          <th>{Localize.get("DOCUMENTS.DETAILS.NEW_VALUE")}</th>
                          <th>{Localize.get("DOCUMENTS.DETAILS.DOCUMENT_USER")}</th>
                          <th>{Localize.get("DOCUMENTS.DETAILS.DOCUMENT_COMMENT")}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {props.documentsCommentHistory.map(comment => (
                          <tr key={comment[0].id}>
                            <td>{formatDateDisplay(comment[0].changeDate)}</td>
                            <td>{translateField(comment[0].documentField)}</td>
                            <td>{translateStatus(comment[0].oldValue)}</td>
                            <td>{translateStatus(comment[0].newValue)}</td>
                            <td>
                              {comment[0].appUser
                                ? `${comment[0].appUser.firstName} ${comment[0].appUser.lastName}`
                                : ""}
                            </td>
                            <td>{comment[0].comment}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  ) : (
                    <Message icon className={styles.no_data_msg}>
                      <Icon name="info circle" />
                      {Localize.get("DOCUMENTS.ITEMLIST.TOOLBAR.EMPTY_DATA")}
                    </Message>
                  )}
                </Accordion.Content>
              </Transition>
            </Accordion>

            <Accordion className={styles.details_accordion}>
              <Accordion.Title
                index={5}
                name="payments"
                active={activeAccordion.payments}
                onClick={accordionHandler}
                className={styles.dms_accordiion_title}
              >
                {activeAccordion.payments ? <Icon name="caret down" /> : <Icon name="caret left" />}

                {Localize.get("DOCUMENTS.DETAILS.HEADER_PAYMENTS")}
              </Accordion.Title>
              <Transition visible={activeAccordion.payments} animation="fade down" duration={300}>
                <Accordion.Content active={activeAccordion.payments}>
                  {props.document.payments && props.document.payments.length > 0 ? (
                    <>
                      <table className={`ui celled table ${styles.bms_item_table}`}>
                        <thead>
                          <tr>
                            <th>{Localize.get("DOCUMENTS.DETAILS.DOCUMENT_DATE")}</th>
                            <th>{Localize.get("DOCUMENTS.DETAILS.DOCUMENT_PAYMENT_STATUS")}</th>
                            <th>{Localize.get("GLOBAL.DETAILS.AMOUNT")}</th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          {document.payments.map(payment => (
                            <tr key={payment.id}>
                              <td>{formatDateDisplay(payment.executionDate)}</td>
                              <td>{formatDocumentPaymentStatus(payment.status)}</td>
                              <td>
                                {new Intl.NumberFormat("de-DE", {
                                  style: "currency",
                                  currency: "EUR"
                                }).format(payment.amount)}
                              </td>
                              <td className={styles.center_cell}>
                                {
                                  <Icon
                                    disabled={
                                      payment.paymentType === "finapi" ||
                                      !manualPaymentPrivileges.delete ||
                                      document.archived
                                    }
                                    className={styles.minus_icon}
                                    name="minus circle"
                                    onClick={e => {
                                      handleOnDeleteClick(e, payment);
                                    }}
                                  />
                                }
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                      <ConfirmDeleteDialog
                        numberOfCheckedItems={1}
                        showModal={showDelModal}
                        confirmClicked={
                          dialogHandler.confirmDeleteDialog.handleDeleteConfirmButtonClick
                        }
                        declineClicked={
                          dialogHandler.confirmDeleteDialog.handleOnDeclineCancelButtonClick
                        }
                      />
                    </>
                  ) : (
                    <Message icon className={styles.no_data_msg}>
                      <Icon name="info circle" />
                      {Localize.get("DOCUMENTS.ITEMLIST.TOOLBAR.EMPTY_DATA")}
                    </Message>
                  )}
                </Accordion.Content>
              </Transition>
            </Accordion>
          </Grid.Column>
        </Grid>
      </div>
    );
  } else {
    documentDetails = (
      <div className={styles.bms_document_details_container}>
        <Message icon>
          <Icon name="info circle" className={styles.empty} />
          {Localize.get("DOCUMENTS.DETAILS.EMPTY")}
        </Message>
      </div>
    );
  }

  const callbackDocSuccess = response => {
    setDocumentForDiagram(response.data);
    setShowDiagramFlowState(true);
  };

  const callbackDocError = response => {};

  const callbackDocFinaly = response => {
    setIsLoadingLoader(false);
  };

  const getDocumentById = async parentId => {
    if (parentId) {
      const serviceGetDocById = new RestService.Document.GetById.Service(parentId);
      serviceGetDocById.setCallbackSuccess(callbackDocSuccess);
      serviceGetDocById.setCallbackError(callbackDocError);
      serviceGetDocById.setCallbackFinally(callbackDocFinaly);
      setIsLoadingLoader(true);
      await serviceGetDocById.call();
    }
  };

  const viewFlow = myId => {
    setShowDiagramFlowState(false);
    getDocumentById(myId);
  };

  return (
    <div className={styles.bms_document_details_container}>
      <div>{getDialog()}</div>
      <div>{getMissingPartnerDialog()}</div>
      <div>{getManualPaymentDialog()}</div>
      <div>
        {showDiagramFlowState ? (
          <DocumentFlow
            viewFlow={viewFlow}
            showDiagramFlowState={showDiagramFlowState}
            setDocumentForDiagram={setDocumentForDiagram}
            setShowDiagramFlowState={setShowDiagramFlowState}
            handler={props.handler}
            document={documentForDiagram !== null ? documentForDiagram : props.document}
          />
        ) : null}
      </div>
      {documentDetails}
    </div>
  );
};

export default DocumentDetails;
